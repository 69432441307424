const config = {
  apiGateway: {
    REGION: 'us-east-2',
    INTERNAL_API_URL: 'https://84hi27eh0f.execute-api.us-east-2.amazonaws.com/prod',
    TARGET_API_URL: 'https://s3jjwhkapk.execute-api.us-east-2.amazonaws.com/api',
    TEAMS_APP_URL: 'https://teams.staging.vale.forwoodsafety.com/teams',
    QS_DASHBOARD_URL: 'https://id.staging.vale.forwoodsafety.com/quicksight-login?qs_redirect_uri=https://us-east-2.quicksight.aws.amazon.com/sn/dashboards/aea4abd3-ab57-49d9-a06e-297eb7cd9b36?session_timeout_redirect_url=id.staging.vale.forwoodsafety.com/quicksight-login',
    CLIENT_TIMEZONE: 'America/Sao_Paulo'
  },
  reactApp: {
    VERSION: 'targets-1.6.0',
    HOSTNAME: 'https://targets.staging.vale.forwoodsafety.com'
  }
};

export default config;